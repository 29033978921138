<template>
  <div class="px-0 py-0 mt-8 shadow-none rounded-none bg-white !bg-transparent flex flex-col items-center">
    <MoleculesTrustpilot
      v-if="!hideSummary"
      :custom-class="customClass"
      layout="reviews"
      class="mb-11"
    />

    <div 
      v-if="layout === 'reviews-slider'" 
      class="trustpilot-reviews text-xs leading-[1.125rem] w-full"
    >
      <swiper-container
        ref="swiper"
        class="trustpilot-swiper-container"
        :style="{
          '--swiper-pagination-bottom': '0',
          '--swiper-pagination-bullet-inactive-color': '#BAC2CF',
          '--swiper-pagination-bullet-inactive-opacity': '1',
          '--swiper-pagination-color': '#475D80'
        }"
      >
        <swiper-slide
          v-for="(review, reviewKey) in reviews.slice(0, 3)"
          :id="`review-${review.id}`"
          :key="reviewKey"
          class="flex flex-col w-full"
          @click="handleReviewLink(review)"
        >
          <div class="pb-8 w-full">
            <div
              class="flex flex-col border border-gray-400 rounded-md p-4 cursor-pointer !text-left items-start min-h-[10.5rem] h-full justify-between"
            >
              <div class="flex flex-col w-full">
                <div class="flex cursor-pointer w-full items-center mb-4">
                  <NuxtImg
                    :src="review.stars"
                    width="76"
                    height="14"
                    loading="lazy"
                    alt="trustpilot rate"
                    class="mr-2 h-[0.875rem] w-[4.75rem]"
                  />
                  <p>
                    by
                    <span class="font-bold"> {{ review.customer }}</span>
                  </p>
                </div>

                <p
                  :id="`review-content-${review.id}`"
                  class="review-content overflow-y-auto h-[4.5rem] break-words"
                >
                  {{ review.text }}
                </p>
              </div>
              <time :datetime="review.createdAt" class="font-bold">
                {{ toHours(review.createdAt) }}
              </time>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>

    <div 
      v-else
      class="trustpilot-reviews text-[0.8125rem] mb-6 last:mb-0 min-h-[26.71875rem]"
    >
      <p class="mb-1">
        Showing our 4 & 5 star reviews
      </p>

      <ul
        class="max-h-[25rem] overflow-y-scroll pt-2"
        :class="{ 'grid grid-cols-1 lg:grid-cols-4': layout === 'reviews-col' }"
      >
        <li
          v-for="(review, reviewKey) in reviews"
          :id="`review-${review.id}`"
          :key="reviewKey"
          class="bg-[#f8f9fa] border-b-1 border-b-[#e2e7ed] rounded-sm mt-4 first:mt-0 p-4 cursor-pointer"
          :class="{'first:mt-4': layout === 'reviews-col' }"
          @click="handleReviewLink(review)"
        >
          <div class="flex justify-between text-[#73839e]">
            <p
              class="flex mb-5 font-bold cursor-pointer"
              :class="{ 'flex-col' : layout === 'reviews-col' }"
            >
              <NuxtImg
                :src="review.stars"
                width="100"
                height="20"
                loading="lazy"
                alt="trustpilot rate"
                class="mr-3"
              />
              by
              {{ review.customer }}
            </p>

            <time :datetime="review.createdAt">
              {{ toHours(review.createdAt) }}
            </time>
          </div>

          <p class="mb-2 font-bold text-sm">
            {{ review.title }}
          </p>

          <p
            :id="`review-content-${review.id}`"
            class="review-content max-h-[72px] overflow-hidden"
            :class="{
              'show-readmore': review.showReadmore,
              'is-collapsed': review.collapsed
            }"
          >
            {{ review.text }}
          </p>

          <div
            v-if="review.showReadmore && !review.collapsed"
            class="font-bold text-center"
            @click.stop="handleCollapseReview(review)"
          >
            Read more
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { register } from 'swiper/element/bundle'
register()

const trustpilotStore = useTrustpilotStore()

defineOptions({
  name: 'MoleculesTrustpilotReviews'
})

const props = defineProps({
  layout: {
    type: String,
    default: 'reviews',
    validator: value => {
      const arr = ['reviews', 'reviews-col', 'reviews-slider']

      if (Array.isArray(value)) {
        return value.filter(e => arr.includes(e))
      }

      return arr.includes(value)
    }
  },
  stars: {
    type: String,
    default: '4,5'
  },
  tags: {
    type: String,
    default: ''
  },
  hideSummary: {
    type: Boolean,
    default: false
  },

  customClass: {
    type: String,
    default: ''
  }
})

// Fetch data if it's not already loaded
if (!trustpilotStore.trustpilotReviews && !trustpilotStore.isLoadingTrustpilotReviews && !trustpilotStore.trustpilotReviewsHasError) {
  // Get the review data from trustpilot
  await trustpilotStore.fetchReviews(removeFalseyObj({
    stars: props.stars,
    tagValue: props.tags
  }))
}
const reviews = ref(trustpilotStore.trustpilotReviews)
const swiper = ref()

watch(() => reviews.value, async val => {
  if (val) {
    await new Promise(resolve => setTimeout(resolve, 1000))
    for (const review of reviews.value) {
      handleReadmore(review)
    }
  }
})

onMounted(async () => {
  await new Promise(resolve => setTimeout(resolve, 1000))
  for (const review of reviews.value) {
    handleReadmore(review)
  }
  if (props.layout === 'reviews-slider') {
    const el = swiper.value

    if (el) {
      const params = {
        slidesPerView: 1.2,
        spaceBetween: 12,
        pagination: {
          clickable: true
        }
      }
      Object.assign(el, params)
      el.initialize()
    }
  }
})

// Extend the dayjs with relativeTime plugin
try {
  dayjs.extend(relativeTime)
} catch (error) {
  throw new Error(error)
}
const toHours = date => dayjs(date).fromNow()

const handleReviewLink = (review = null) => {
  return window.open(`https://au.trustpilot.com/reviews/${review.id}`, '_blank')
}

const handleReadmore = review => {
  const reviewEl = document.getElementById(`review-content-${review.id}`)
  // Show read more only when the element's height has reached 72px & is not collapsed
  review.showReadmore = reviewEl?.clientHeight === 72 && !review.collapsed

  // Trigger reactivity update
  reviews.value = [...reviews.value]
}

const handleCollapseReview = review => {
  review.collapsed = true
  // Trigger reactivity update
  reviews.value = [...reviews.value]
}
</script>

<style lang="scss">
.review-content {
  @apply relative;

  &.is-collapsed {
    @apply max-h-full;
  }

  &.show-readmore:not(.is-collapsed) {
    &:after {
      @apply content-[''];
      @apply absolute top-0 left-0 w-full h-full;
      background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          #f8f9fa 95%,
          #f8f9fa 100%
      );
    }
  }
}

</style>
